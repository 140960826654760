import { Injectable, inject } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import {
  switchMap,
  catchError,
  of,
  map,
  mergeMap,
  tap,
  filter,
  withLatestFrom,
  take,
  startWith,
} from 'rxjs';

import { PostPipelineStatusRequest, SharedService, TasksService } from '@offconon/core-api';
import * as PipelineActions from '@offconon/shared/ui/pipeline-flow-common-render';

import * as TaskActions from './task.actions';
import { fetchTasksForStep } from './task.actions';
import { selectTaskList } from './task.selectors';

@Injectable()
export class TaskEffects {
  private actions$ = inject(Actions);
  private sharedService = inject(SharedService);
  private tasksService = inject(TasksService);
  private messageService = inject(MessageService);
  private translateService = inject(TranslateService);
  private store = inject(Store);

  fetchTaskList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TaskActions.fetchTaskList),
      switchMap((action) => {
        return this.tasksService
          .tasksTaskList(action.page, action.pageSize, action.searchJson)
          .pipe(
            switchMap((taskResponse) => {
              return of(
                TaskActions.fetchTaskListSuccess({
                  task: {
                    ...taskResponse,
                    results:
                      taskResponse.results?.sort((a, b) => a.step_order_id! - b.step_order_id!) ||
                      [],
                  },
                }),
              );
            }),
            catchError((error) => of(TaskActions.loadTaskFailure({ error }))),
          );
      }),
    ),
  );

  fetchTaskById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TaskActions.fetchTaskById),
      withLatestFrom(this.store.select(selectTaskList)),
      filter(([action, taskList]) => !taskList.find((task) => task.id === action.id)),

      mergeMap(([action]) => {
        return this.tasksService.tasksTaskList(1, 1, { id: action.id }).pipe(
          switchMap((task) => {
            return of(TaskActions.fetchTaskListSuccess({ task }));
          }),
          catchError((error) => of(TaskActions.loadTaskFailure({ error }))),
        );
      }),
    ),
  );

  fetchTasksForStep$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchTasksForStep),
      mergeMap((action) =>
        this.tasksService
          .tasksTaskList(1, 100, { step_id: action.stepId })
          .pipe(map((taskResponse) => TaskActions.fetchTaskListSuccess({ task: taskResponse }))),
      ),
    ),
  );

  createTask$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TaskActions.createTask),
      mergeMap((action) =>
        this.tasksService.tasksTaskCreate(action.task).pipe(
          map((task) => TaskActions.createTaskSuccess({ task })),
          tap((createSuccessAction) => {
            this.messageService.add({
              severity: 'success',
              summary: this.translateService.instant('Successful'),
              detail: this.translateService.instant('Your data has been successfully saved.'),
            });

            if (action.stepId) {
              this.store.dispatch(
                TaskActions.moveTask({
                  task: {
                    service_id: createSuccessAction.task.id,
                    step_id: action.stepId,
                    service_type: PostPipelineStatusRequest.ServiceTypeEnum.Task,
                  },
                }),
              );
            }
          }),
        ),
      ),
    ),
  );

  updateTask$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TaskActions.updateTask),
      mergeMap((action) =>
        this.tasksService.tasksTaskPartialUpdate(action.task).pipe(
          map((task) => TaskActions.updateTaskSuccess({ task })),
          tap(() => {
            this.messageService.add({
              severity: 'success',
              summary: this.translateService.instant('Successful'),
              detail: this.translateService.instant('Your data has been successfully saved.'),
            });
          }),
        ),
      ),
    ),
  );

  moveTask$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TaskActions.moveTask),
      mergeMap((action) =>
        this.sharedService.sharedPipelineStatusCreate(action.task).pipe(
          map((task) =>
            TaskActions.moveTaskSuccess({ task, prevContainerId: action.prevContainerId }),
          ),
          tap(() => {
            this.messageService.add({
              severity: 'success',
              summary: this.translateService.instant('Successful'),
              detail: this.translateService.instant('Your data has been successfully saved.'),
            });
          }),
        ),
      ),
    ),
  );

  fetchTaskListByPipelineId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TaskActions.fetchTaskListByPipelineId),
      mergeMap((action) =>
        this.actions$.pipe(
          ofType(PipelineActions.fetchPipelineSuccess),
          take(1),
          mergeMap((successAction) => {
            const fetchTasksActions =
              successAction.pipeline?.steps
                ?.filter((step): step is { id: number } => typeof step.id === 'number')
                .map((step) => fetchTasksForStep({ stepId: step.id })) || [];
            return fetchTasksActions;
          }),
          startWith(
            PipelineActions.fetchPipeline({
              page: 1,
              pageSize: 1,
              searchJson: { id: action.pipelineId },
            }),
          ),
        ),
      ),
    ),
  );
}
