import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on, Action } from '@ngrx/store';

import { Task } from '@offconon/core-api';

import * as TaskActions from './task.actions';
import { TaskEntity } from './task.models';

export const TASK_FEATURE_KEY = 'task';

export interface TaskState extends EntityState<TaskEntity> {
  error?: string | null; // last known error (if any)
  taskList: Task[];
}

export interface TaskPartialState {
  readonly [TASK_FEATURE_KEY]: TaskState;
}

export const taskAdapter: EntityAdapter<TaskEntity> = createEntityAdapter<TaskEntity>();

export const initialTaskState: TaskState = taskAdapter.getInitialState({
  taskList: [],
});

const reducer = createReducer(
  initialTaskState,
  on(TaskActions.initTask, (state) => ({ ...state, stepsLoaded: false, error: null })),
  on(TaskActions.loadTaskSuccess, (state, { task }) =>
    taskAdapter.setAll(task, { ...state, loaded: true }),
  ),
  on(TaskActions.loadTaskFailure, (state, { error }) => ({ ...state, error })),
  on(TaskActions.fetchTaskListSuccess, (state, { task }) => {
    if (task?.results) {
      const sorted = [...task.results].sort((a: any, b: any) => a.step_order_id - b.step_order_id);

      const taskMap = new Map([
        ...Object.entries(
          state.taskList.reduce((acc, task) => ({ ...acc, [task.id || 0]: task }), {}),
        ),
        ...Object.entries(sorted.reduce((acc, task) => ({ ...acc, [task.id || 0]: task }), {})),
      ]);

      return {
        ...state,
        taskList: Array.from(taskMap.values()).sort(
          (a: any, b: any) => a.step_order_id - b.step_order_id,
        ),
      } as any;
    }
    return state;
  }),

  on(TaskActions.setLoaded, (state, { loaded }) => ({ ...state, stepsLoaded: loaded })),

  on(TaskActions.createTaskSuccess, (state, { task }) => {
    return {
      ...state,
      taskList: [...state.taskList, task],
    };
  }),
  on(TaskActions.updateTaskSuccess, (state, { task }) => {
    return {
      ...state,
      taskList: state.taskList.map((t) => (t.id === task.id ? task : t)),
    };
  }),
  on(TaskActions.moveTaskSuccess, (state, { task, prevContainerId }) => {
    const movedTask = {
      ...state.taskList.find((t) => t.id === task.service_id),
      step_order_id: task.order_id,
      step_id: task.step_id,
    };

    if (!movedTask) {
      return state;
    }

    // Remove the moved task from the current list
    const tasksWithoutMoved = state.taskList.filter((t) => t.id !== movedTask.id);

    // Case 1: Moving within the same container
    if (movedTask.step_id === prevContainerId) {
      const containerTasks = tasksWithoutMoved.filter((t) => t.step_id === movedTask.step_id);

      // Insert task at new position and update all order IDs
      const reorderedTasks = [
        ...containerTasks.slice(0, movedTask.step_order_id),
        movedTask,
        ...containerTasks.slice(movedTask.step_order_id),
      ].map((t, index) => ({ ...t, step_order_id: index }));

      return {
        ...state,
        taskList: [
          ...tasksWithoutMoved.filter((t) => t.step_id !== movedTask.step_id),
          ...reorderedTasks,
        ],
      };
    }

    // Case 2: Moving between containers
    const sourceContainerTasks = tasksWithoutMoved
      .filter((t) => t.step_id === prevContainerId)
      .map((t, index) => ({ ...t, step_order_id: index })); // Reorder source container

    const targetContainerTasks = tasksWithoutMoved.filter((t) => t.step_id === movedTask.step_id);

    // Insert into target container and update order IDs
    const reorderedTargetTasks = [
      ...targetContainerTasks.slice(0, movedTask.step_order_id),
      movedTask,
      ...targetContainerTasks.slice(movedTask.step_order_id),
    ].map((t, index) => ({ ...t, step_order_id: index }));

    return {
      ...state,
      taskList: [
        ...tasksWithoutMoved.filter(
          (t) => t.step_id !== prevContainerId && t.step_id !== movedTask.step_id,
        ),
        ...sourceContainerTasks,
        ...reorderedTargetTasks,
      ],
    };
  }),
);

export function taskReducer(state: TaskState | undefined, action: Action) {
  return reducer(state, action);
}
