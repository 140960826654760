import { createAction, props } from '@ngrx/store';

import {
  PaginatedTaskList,
  PatchedPatchTaskRequest,
  PipelineStatus,
  PostPipelineStatusRequest,
  PostTaskRequest,
  Task,
} from '@offconon/core-api';

import { TaskEntity } from './task.models';

export const initTask = createAction('[Task Page] Init');

export const loadTaskSuccess = createAction(
  '[Task/API] Load Task Success',
  props<{ task: TaskEntity[] }>(),
);

export const loadTaskFailure = createAction(
  '[Task/API] Load Task Failure',
  props<{ error: any }>(),
);

export const fetchTaskList = createAction(
  '[Task/API] Fetch Task List',
  props<{
    page?: number;
    pageSize?: number;
    searchJson?: {
      business_id?: number;
      creator_co_worker_id?: number;
      responsible_co_worker_id?: number;
      report_to_co_worker_id?: number;
      project_workgroup_id?: number;
    };
  }>(),
);

export const fetchTaskListSuccess = createAction(
  '[Task/API] Fetch Task List Success',
  props<{ task?: PaginatedTaskList }>(),
);

export const setLoaded = createAction('[Task/API] Set Loaded', props<{ loaded: boolean }>());

export const fetchTasksForStep = createAction(
  '[Task/API] Fetch Tasks For Step',
  props<{ stepId: number }>(),
);

export const createTask = createAction(
  '[Task/API] Create Task',
  props<{ task: PostTaskRequest; stepId?: number }>(),
);
export const createTaskSuccess = createAction(
  '[Task/API] Create Task Success',
  props<{ task: Task }>(),
);
export const updateTask = createAction(
  '[Task/API] Update Task',
  props<{ task: PatchedPatchTaskRequest }>(),
);
export const updateTaskSuccess = createAction(
  '[Task/API] Update Task Success',
  props<{ task: Task }>(),
);
export const deleteTask = createAction('[Task/API] Delete Task', props<{ id: number }>());
export const deleteTaskSuccess = createAction(
  '[Task/API] Delete Task Success',
  props<{ id: number }>(),
);

export const moveTask = createAction(
  '[Task/API] Move Task',
  props<{ task: PostPipelineStatusRequest; prevContainerId?: number }>(),
);

export const moveTaskSuccess = createAction(
  '[Task/API] Move Task Success',
  props<{ task: PipelineStatus; prevContainerId?: number }>(),
);

export const fetchTaskById = createAction('[Task/API] Fetch Task By Id', props<{ id: number }>());

export const fetchTaskListByPipelineId = createAction(
  '[Task/API] Fetch Task By Pipeline Id',
  props<{ pipelineId: number }>(),
);
