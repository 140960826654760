import { createFeatureSelector, createSelector } from '@ngrx/store';

import { selectPipeLineSteps } from '@offconon/shared/ui/pipeline-flow-common-render';

import { TASK_FEATURE_KEY, TaskState, taskAdapter } from './task.reducer';

// Lookup the 'Task' feature state managed by NgRx
export const selectTaskState = createFeatureSelector<TaskState>(TASK_FEATURE_KEY);

const { selectAll, selectEntities } = taskAdapter.getSelectors();

export const selectTaskError = createSelector(selectTaskState, (state: TaskState) => state.error);

export const selectAllTask = createSelector(selectTaskState, (state: TaskState) =>
  selectAll(state),
);

export const selectTaskEntities = createSelector(selectTaskState, (state: TaskState) =>
  selectEntities(state),
);

export const selectTaskList = createSelector(selectTaskState, (state) => state.taskList);

export const selectTaskById = (id: number) =>
  createSelector(selectTaskState, (state) => {
    return state.taskList.find((task) => task.id === Number(id));
  });
export const selectPipelineListWithTasks = createSelector(
  selectPipeLineSteps,
  selectTaskList,
  (pipelineList, taskList) => {
    if (!pipelineList) return null;

    return {
      ...pipelineList,
      steps: pipelineList.steps?.map((step) => {
        return {
          ...step,
          cards: taskList.filter((task) => task.step_id === step.id),
        };
      }),
    };
  },
);
